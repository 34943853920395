@import "./../theme-bootstrap";

.social-login {
  padding: 1.33%;
  &__container {
    text-align: center;
    padding: 20px 0;
  }
  &__divider {
    @include h7;
    @include swap_direction(margin, 15px auto 0);
    width: 100%;
    margin-bottom: 18px;
  }
  &__terms {
    margin-top: 15px;
    padding: 0;
    text-align: $ldirection;
    p {
      margin: 0;
    }
  }
  &__title,
  &__info {
    display: none;
  }
  &__email-opt-in {
    text-align: $ldirection;
    padding-top: 15px;
  }
  .tooltip {
    display: none;
  }
}
/* GNAV styling */
.social-login.gnav {
  .social-login {
    &__divider {
      margin-bottom: 18px;
      .inner {
        background: $color-lighter-gray;
      }
    }
    &__terms {
      padding: 0;
    }
    &__email-opt-in {
      @include swap_direction(padding, 15px 0 0 0);
    }
  }
}
/* Disconnect button styling */
#facebook_disconnect {
  margin-left: 22px;
  display: inline-flex;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
/* Order confirmation page */
.checkout--confirmation-page {
  .social-login {
    &__container {
      .social-login__title {
        font-size: 28px;
      }
      text-align: $ldirection;
      padding-bottom: 0;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
    &__email-opt-in {
      padding-#{$ldirection}: 0;
    }
  }
}
/* Checkout page styling */
.checkout-panel {
  label.social-login__opt-in-label {
    padding-#{$ldirection}: 0;
  }
}
/* Social info section on Account preferences page */
.social-info {
  margin-top: 30px;
  &__content {
    padding-#{$ldirection}: 0;
  }
  &__connect {
    margin-bottom: 30px;
  }
}
